.header {
  max-width: 992px;
  padding: 32px 32px 0;
  margin: 0 auto;

  @media (max-width: 599px) {
    display: flex;
    padding: 16px 16px 0;
  }
}

.header__text {
  line-height: 36px;
  text-align: center;
  opacity: 0.6;

  @media (max-width: 599px) {
    margin-right: auto;
  }
}

.header__actions {
  float: right;
  color: $dark-secondary-text;
  margin-top: -52px;

  @media (max-width: 599px) {
    margin-top: 0;
  }
}

.tc-sticky-toolbar {
  position: sticky;
  top: 64px;
  z-index: 10;

  @media (max-width: 599px) {
    top: 56px;
  }
}
