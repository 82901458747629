@import '~theme/include';

.tc-container {
  width: 100%;
  padding: 24px;

  @media screen and (max-width: 1279px) {
    padding: 10px;
  }

  @media (max-width: 599px) {
    padding: 16px;
  }

  &--large {
    max-width: 960px;
    margin: 0 auto;
  }

  &--medium {
    max-width: 720px;
    margin: 0 auto;
  }

  &--small {
    max-width: 640px;
    margin: 0 auto;
  }

  &--flex {
    display: flex;
    align-items: stretch;

    @media (max-width: 599px) {
      flex-direction: column;
    }
  }

  > * {
    flex: 1 1 auto;
  }
}

.button-content + .tc-container {
  padding-top: 10px;
}

.tc-container__mat-fab.mat-fab {
  position: fixed;
  z-index: 11;
  right: 16px;
  margin-top: -52px;

  @media (max-width: 599px) {
    bottom: 16px;
    margin-top: 0;
  }
}

.tc-container__fab-placeholder {
  width: 64px;
  max-width: 64px;

  @media (max-width: 599px) {
    width: auto;
    max-width: none;
    height: 72px;
  }
}
