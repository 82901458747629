// Overrides need specificity to work
body {
  /** MatFormField */
  .mat-form-field {
    display: block;
    flex: 1 1 auto;
  }

  .mat-form-field-appearance-legacy {
    $label-color: $original-dark-secondary-text;

    .mat-form-field-label {
      color: $label-color;
    }

    .mat-hint {
      color: $label-color;
    }
  }

  .mat-form-field-infix {
    width: auto;
  }

  /** Core */
  .mat-option .mat-icon {
    margin-right: 0;
  }

  /** MatToolbar */
  .mat-toolbar h1 {
    font-size: 24px;
  }

  .mat-toolbar .mat-icon-button:first-child {
    margin-left: -8px;
    margin-right: 8px;
  }

  /** MatDrawer */
  .mat-drawer-container {
    flex: 1 1 auto;
  }

  .mat-drawer-container,
  .mat-drawer-content {
    overflow: visible;
  }

  .mat-drawer {
    width: 280px;
  }

  .mat-drawer-side {
    border-right: 1px solid $dark-dividers;
  }

  /** MatList */
  .mat-list,
  .mat-nav-list {
    .mat-subheader {
      text-transform: uppercase;
      justify-content: space-between;
    }
  }

  /** MatSelectionList */
  .mat-selection-list[dense] .mat-list-option {
    height: 24px;
    font-size: 13px;
    margin-top: 0;

    .mat-pseudo-checkbox {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }

    .mat-pseudo-checkbox-checked + .mat-list-text {
      font-weight: 500;
      color: $dark-primary-text;
    }

    .mat-pseudo-checkbox-checked::after {
      top: 2px;
      width: 8px;
      height: 4px;
    }

    .mat-list-text {
      flex-direction: row;
    }

    .mat-list-text small {
      color: $dark-secondary-text;
      margin-left: auto;
    }
  }

  /** MatButton */
  .mat-button {
    min-width: auto;
  }

  .mat-button .mat-button-focus-overlay {
    background-color: $dark-hover;
  }

  /** MatMenu */
  .mat-menu-content {
    padding-top: 0;
    padding-bottom: 0;
  }

  /** MatDialog */
  .mat-dialog-container {
    min-width: 352px;
    max-width: 100%;

    p {
      max-width: 352px;
    }
  }

  .mat-dialog-content {
    line-height: 24px;
    font-size: 16px;
  }

  .mat-dialog-actions {
    justify-content: flex-end;
    padding: 8px 0;
    margin-left: -16px;
    margin-right: -16px;
  }

  /** MatPaginator */
  @media (max-width: 599px) {
    .mat-paginator-page-size {
      display: none;
    }
  }

  /** MatTable */
  .mat-header-cell,
  .mat-cell {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;


    @media screen and (max-width: 1279px) {
      font-size: 12px;
    }

  }
}

.medium-menu.mat-menu-panel {
  max-width: 480px;
}

.mat-chip-list {
  &.mat-chip-list-stacked .mat-chip-list-wrapper {
    display: inline-flex;
    flex-wrap: nowrap;
    flex-direction: row;

    mat-chip.mat-chip.mat-standard-chip {
      font-weight: 500;
      text-decoration: none;
      color: $dark-gray;
      margin-left: 15px;
      margin-top: 10px;

      .mat-chip-remove {
        color: $tc-negative;
        opacity: 0.7;
      }
    }
  }

  .mat-chip-list-wrapper {
    mat-chip {
      background-color: #ffffff;
      display: flex;
      height: auto;

      .mat-chip-remove {
        margin-left: auto;
        color: $tc-negative;
        opacity: 0.7;
      }
    }

    .mat-chip-ripple {
      border: 1px solid $transparent-blue;
    }
  }
}
