@use '@angular/material' as mat;
@import '~theme/include';

.tc-accordion-form {
  display: block;
  margin-bottom: 32px;

  .mat-expansion-panel-header-title,
  .mat-expansion-panel-header-description {
    flex-basis: 0;
  }

  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: $original-dark-secondary-text;
  }

  .mat-expansion-panel-header-description {
    display: block;
  }

  .mat-expansion-indicator {
    width: 8px;
    margin-left: -8px;
  }

  .full-width {
    .mat-expansion-panel-body {
      margin-left: 0;
    }
  }

  .mat-expansion-panel-body {
    margin-left: calc(26% + 30px);

    @media (max-width: 599px) {
      margin-left: 0;
    }
  }

  &--medium {
    .mat-expansion-panel-body {
      margin-left: calc(26% + 40px);
    }
  }

  .mat-action-row button[type='button'] {
    color: mat.get-color-from-palette(map-get($app-theme, foreground), secondary-text);
  }
}
