.cropper-container {
  background: #eee;
}

.cropper-view-box {
  outline-color: white;
}

.cropper-dashed {
  border-color: white;
}

.cropper-line,
.cropper-point {
  background-color: white;
}
