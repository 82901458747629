.tc-columns {
  $compact-padding: 12px;
  display: flex;

  > div {
    padding: 24px;
    margin-right: auto;

    @media screen and (max-width: 1279px) {
      padding: 10px;
    }
  }

  > div:last-child {
    margin-right: 0;
  }

  &.tc-columns--compact > div {
    padding-bottom: $compact-padding;
    padding-top: $compact-padding;
  }
}

.tc-columns__sidebar {
  border-right: 1px solid $dark-dividers;

  &.tc-row {
    flex-direction: column;
    display: flex;
  }

  &.tc-sidebar-sticky {
    position: sticky;
    top: 0;
  }

  &.tc-columns__sidebar--actions {
    margin-left: -12px;

    .mat-subheader {
      display: block;
      text-align: center;
    }
  }
}
