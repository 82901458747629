.text-placeholder,
.loading-text::before {
  display: inline-block;
  vertical-align: middle;
  height: 12px;
  background-color: $dark-hover;
}

.text-placeholder {
  width: 80px;
}

.loading-text::before {
  content: '';
  width: 50%;
}
