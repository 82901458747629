@use '@angular/material' as mat;
@import '~theme/include';

.spacer {
  flex: 1 0 auto;
}

.spacer--small {
  flex: 0 1 96px;
}

.truncate--wrap {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tc-sticky-footer {
  position: sticky;
  z-index: 1;
  background-color: rgba(white, 0.8);
  backdrop-filter: saturate(180%) blur(5px);
  bottom: 0;
}

.mat-chip.mat-negative {
  background-color: $tc-negative;
  color: #fff;
}

.mat-button.mat-positive {
  color: $tc-positive;
}
.mat-button.mat-negative {
  color: $tc-negative;
}

.mat-button.mat-warning {
  color: $tc-warning;
}

.light {
  color: $dark-secondary-text;
}

.long-label > .mat-checkbox-layout {
  white-space: normal !important;
}

.tc-media,
.mat-toolbar .tc-media {
  display: flex;

  &__image {
    margin-right: 16px;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__title {
    line-height: 32px;
    font-size: 24px;
    font-weight: 500;
  }

  &__subtitle {
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.tc-bullets {
  display: flex;
  list-style: none;
  min-height: 16px;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    white-space: nowrap;
  }

  li + li {
    padding-left: 24px;
  }

  li + li::before {
    position: absolute;
    left: 10px;
    content: '•';
  }
}

.tc-upcase {
  text-transform: uppercase !important;
}

.tc-text-center {
  text-align: center !important;
}

.tc-webkit-visible-scrollbars {
  $thumb-margin: 2px;
  $thumb-size: 7px;
  $track-size: $thumb-size + 2 * $thumb-margin;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: $track-size;
    width: $track-size;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: rgba(#000, 0.2);
    border-radius: $track-size * 0.5;
    border: $thumb-margin solid transparent; // workaround as margin isn't supported
    box-shadow: 0 0 0 1px rgba(#fff, 0.15);
  }
}

.tc-pad-left {
  padding: 0 0 0 6px;
}

.tc-pad-right {
  padding-right: 6px;
}

.tc-diff__line {
  display: grid;
  grid-template-columns: 25% 35% 40%;
  color: #ffffff;
  background-color: $tc-warning;
  border-radius: 3px;
  margin: 2px 0;
}


.tc-request__line {
  display: grid;
  grid-template-columns: 25% 35% 40%;
  border-radius: 3px;
  margin: 2px 0;
}

.tc-formatted {
  white-space: pre-wrap !important;
}

.space-after:after {
  content: '\00a0';
}
.background-image {
  height: 100%;
  flex: 1 1 auto;
  background-image: url(~assets/Login-page.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 160px;
}

.element--disabled {
  pointer-events: none;
  color: $dark-secondary-text;
}

[data-suffix]::after {
  content: attr(data-suffix);
}

.tc-change {
  background-color: $tc-warning;
  border-radius: 3px;
  padding-left: 3px;
  padding-right: 3px;
  margin-bottom: 2px;
}

.delivery-date {
  border-radius: 5px;
  padding: 1px 5px;
}

.overdue {
  background-color: mat-color($tc-accent-palette, A700);
  border-radius: 5px;
  padding: 1px 5px;
}

.space-right {
  margin-right: 10px;
}

.section-item {
  margin-top: 0;
  margin-bottom: 0;
}

.tc-btn-light {
  background-color: $light-gray !important;
  color: $tc-primary !important;
}
