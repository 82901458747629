@import '~theme/include';

.card-form {
  display: flex;
  justify-content: center;
  padding: 0 16px;
  margin-top: -40px;

  .mat-card {
    flex: 1 0 auto;
    width: 100%;
    max-width: 400px;
    margin: 0;
  }

  .mat-card > .mat-card-actions:last-child {
    margin-bottom: 0;

    @media (max-width: 599px) {
      margin-bottom: -8px;
    }
  }

  .mat-card-actions .mat-raised-button,
  .mat-card-actions .mat-raised-button:first-child {
    float: right;
    margin-right: 16px;

    @media (max-width: 599px) {
      margin-right: 8px;
    }
  }

  .mat-card-actions .mat-button {
    @media (max-width: 599px) {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
