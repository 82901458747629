@use "sass:math";

.mat-drawer-container--backdrop {
  .mat-drawer-backdrop.mat-drawer-shown {
    background-color: $dark-dividers;
  }
}

.mat-drawer-container.mat-drawer-container--overflow {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: visible;
  -webkit-overflow-scrolling: auto;

  > .mat-drawer-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: visible;
  }
}

.mat-form-field--clear {
  .mat-form-field-wrapper {
    height: 100%;
    padding-bottom: 0;
  }

  .mat-form-field-flex {
    height: 100%;
    align-items: center;
  }

  .mat-form-field-prefix {
    color: $dark-secondary-text;
    margin: 0 16px;

    @media (max-width: 599px) {
      margin-left: 0;
    }
  }

  .mat-form-field-infix {
    border-top: none;
  }

  .mat-form-field-underline {
    display: none;
  }

  &.mat-focused .mat-form-field-label {
    color: $dark-secondary-text;
  }
}

.mat-form-field--fixed-prefix {
  .mat-form-field-prefix {
    flex-basis: 16px;
  }

  .mat-form-field-label-wrapper,
  .mat-form-field-label {
    overflow: visible;
  }

  .mat-form-field-label-wrapper {
    left: -16px;
  }
}

.mat-form-field--mark-dirty.ng-dirty {
  .mat-form-field-flex {
    background-color: rgba($tc-primary, 0.1);
  }
}

.mat-progress-bar.mat-form-field__progress-bar {
  position: absolute;
  left: -12px;
  right: -12px;
  bottom: 0;
  width: auto;
  height: 2px;
  z-index: 1;
}

.mat-toolbar.mat-toolbar--autosize {
  &.mat-toolbar-single-row,
  .mat-toolbar-row {
    height: auto;
  }
}

.mat-tab-nav-bar--toolbar {
  border-bottom: none;

  .mat-tab-links {
    display: flex;
  }

  .mat-tab-link {
    flex: 0 1 auto;
    min-width: 0;
    opacity: 1;

    @media (max-width: 599px) {
      padding: 0 16px;
    }
  }

  .mat-toolbar.mat-primary & .mat-tab-link {
    color: white;
  }
}

.mat-fab.mat-fab--toolbar {
  z-index: 11;
  bottom: -32px;

  @media (max-width: 599px) {
    position: fixed;
    right: 16px;
    bottom: 16px;
  }
}

.mat-card.mat-card--compact {
  $compact-padding: 12px;

  padding-bottom: $compact-padding;
  padding-top: $compact-padding;

  .mat-card-content.mat-card-content--no-margin {
    margin-left: -$compact-padding !important;
    margin-right: -$compact-padding !important;
    margin-bottom: 0;

    &:last-child {
      margin-bottom: -$compact-padding !important;
    }

    &:first-child {
      margin-top: -$compact-padding !important;
    }
  }
}

.mat-card-content.mat-card-content--no-margin {
  margin-left: -16px !important;
  margin-right: -16px !important;
  margin-bottom: 0;

  @media (max-width: 599px) {
    margin-left: -16px !important;
    margin-right: -16px !important;
  }

  &:last-child {
    margin-bottom: -16px !important;
  }

  &:first-child {
    margin-top: -16px !important;
  }
}

.mat-cell,
.mat-header-cell {
  &.mat-cell--right {
    align-items: flex-end;
    text-align: right;

    &:not(:last-child) {
      padding-right: 16px;
    }
  }
}

.order-details .mat-tab-body-wrapper {
  height: calc(100% - 50px);
}

.mat-list-item.auto-height {
  height: auto !important;
}

.mat-list-item .property {
  align-self: baseline;
}

.mat-line.wrap {
  max-width: 100%;
  word-break: break-all;
}

.value.wrap {
  word-break: break-all;
}

.value.wrap * {
  max-width: 100%;
  word-break: break-all;
}

.mat-list,
.mat-nav-list {
  .mat-list-item.mat-list-item--multi-line {
    height: auto !important;

    .mat-list-item-content {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }

  .mat-list-item.mat-list-item--disabled {
    pointer-events: none;
    color: $dark-secondary-text;
  }

  .mat-list-item.mat-list-item--stacked-btn {
    height: auto !important;

    .mat-list-item-content {
      flex-direction: column;
      padding: 16px 0;
      text-align: center;
    }

    .mat-icon {
      margin-top: -5px;
    }

    .mat-list-text {
      padding: 0 !important;
    }

    .mat-line {
      white-space: normal;
    }
  }
}

@at-root {
  $compact-padding: 8px;
  $compact-height: 48px - 2 * $compact-padding;

  .mat-list.mat-list--compact,
  .mat-nav-list.mat-nav-list--compact {
    .mat-subheader {
      height: $compact-height;

      &:first-child {
        margin-top: math.div($compact-padding, -2);
        padding-bottom: $compact-padding;
        padding-top: $compact-padding;
      }
    }
  }

  .mat-list.mat-list--compact {
    .mat-list-item,
    .mat-list-option {
      height: $compact-height;

      .mat-list-item-content,
      &.mat-multi-line .mat-list-item-content {
        padding-bottom: $compact-padding;
        padding-top: $compact-padding;
      }
    }

    &.mat-list--properties {
      .mat-2-line .property,
      .mat-3-line .property {
        padding-top: $compact-padding;
      }
    }
  }

  .mat-nav-list.mat-nav-list--compact {
    .mat-list-item.mat-list-item--multi-line .mat-list-item-content {
      padding-bottom: $compact-padding;
      padding-top: $compact-padding;
    }
  }
}

.mat-list.mat-list--properties {
  .mat-list-item {
    font-size: 14px;
    line-height: 20px;
  }

  .property {
    width: 45%;
    color: $dark-secondary-text;
  }

  .mat-2-line .property,
  .mat-3-line .property {
    align-self: flex-start;
    padding-top: 16px;
  }

  .mat-multi-line .property {
    align-self: flex-start;
  }

  .value {
    width: 55%;

    h4.mat-line {
      display: flex;
      justify-content: space-between;

      font-size: 14px;
      line-height: 14px;
      font-weight: 400;
      margin: 0;
      overflow: initial;
      text-overflow: initial;
      white-space: initial;
    }

    p.mat-line {
      font-size: 12px;
      color: $dark-secondary-text;
      margin: 0;
      overflow: initial;
      text-overflow: initial;
      white-space: initial;
    }
  }

  .full {
    width: 95%;
  }

  .secondary {
    color: $dark-secondary-text;
  }
}

.mat-subheader--border {
  border-bottom: 1px solid $dark-dividers;
}

.mat-nav-list.mat-nav-list--actions {
  .mat-list-item {
    font-size: 14px;
  }

  a:hover {
    color: $tc-accent;
  }
}

.cdk-overlay-pane--fixed-top {
  align-self: flex-start;
  margin-top: calc(17.5vh - 63.5px);
}

.cdk-overlay-pane {
  &.cdk-overlay-pane--demo {
    margin-top: 56px !important;
  }
}

.mat-snack-bar-container {
  .mat-simple-snackbar-action {
    color: inherit;
  }

  &.mat-snack-bar-container--demo {
    background: $tc-yellow;
    color: $dark-primary-text;
  }

  &.mat-snack-bar-container--error {
    background: $tc-negative;
    color: invert($dark-primary-text);
  }

  &.mat-snack-bar-container--info {
    background: $tc-notice;
    color: invert($dark-primary-text);
  }

  &.mat-snack-bar-container--success {
    background: $tc-positive;
    color: invert($dark-primary-text);
  }

  &.mat-snack-bar-container--warning {
    background: $tc-warning;
    color: invert($dark-primary-text);
  }
}
.vertical > .mat-list-base .mat-list-item .mat-list-item-content, .mat-list-base .mat-list-option .mat-list-item-content {
  padding-left: 0;
  padding-right: 0;
}

.vertical > .mat-list-base,
.vertical > .mat-list.mat-list--compact .mat-list-item .mat-list-item-content,
.vertical > .mat-list .mat-list-item.mat-list-item--multi-line .mat-list-item-content,
.vertical > .mat-list.mat-list--compact .mat-list-item.mat-multi-line .mat-list-item-content,
.vertical > .mat-list.mat-list--compact .mat-list-option .mat-list-item-content,
.vertical > .mat-list.mat-list--compact .mat-list-option.mat-multi-line .mat-list-item-content {
  padding-top: 0;
}

.no--padding {
  .mat-expansion-panel-body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
