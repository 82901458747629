/**
 * Colors
 */
$tc-primary: #0071bd;
$tc-accent: #f05918;
$tc-dark: #003c64;
$tc-gray: #444;
$tc-yellow: #ffecb3;

$tc-action: #448aff;
$tc-notice: #7c4dff;
$tc-warning: #ffa000;
$tc-notes: #ffeca9;
$tc-positive: #4caf50;
$tc-negative: #f44336;
$tc-done: #388e3c;

$dark-hover: rgba(black, 0.04);
$light-hover: rgba(white, 0.04);

$original-dark-secondary-text: $dark-secondary-text;
$original-dark-disabled-text: $dark-disabled-text;

$dark-secondary-text: rgba(black, 0.87);
$dark-disabled-text: rgba(black, 0.54);

$dark-gray: #606369;
$transparent-blue: #0071bd80;
$medium-gray: #949494;
$light-gray: #F5F5F5;
$off-white: #ECEFF2;

// Source: http://mcg.mbitson.com/#!?mcgpalette0=%230071bd#%2F
$tc-primary-palette: (
  50: #e0eef7,
  100: #b3d4eb,
  200: #80b8de,
  300: #4d9cd1,
  400: #2686c7,
  500: #0071bd,
  600: #0069b7,
  700: #005eae,
  800: #0054a6,
  900: #004298,
  A100: #c3d9ff,
  A200: #90b8ff,
  A400: #5d97ff,
  A700: #4487ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

// Source: http://mcg.mbitson.com/#!?mcgpalette0=%23f05918#%2F
$tc-accent-palette: (
  50: #fdebe3,
  100: #fbcdba,
  200: #f8ac8c,
  300: #f58b5d,
  400: #f2723b,
  500: #f05918,
  600: #ee5115,
  700: #ec4811,
  800: #e93e0e,
  900: #e52e08,
  A100: #ffffff,
  A200: #ffdfda,
  A400: #ffb3a7,
  A700: #ff9d8e,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);
