@use '@angular/material' as mat;
.tc-table {
  display: block;
  width: 100%;

  > thead {
    backdrop-filter: saturate(180%) blur(5px);
    background-color: rgba(white, 0.8);
    display: block;
    overflow: hidden;
    position: sticky;
    top: 128px;
    z-index: 1;

    @media (max-width: 599px) {
      top: 112px;
    }
  }

  > tbody {
    display: block;
    overflow-x: auto;
  }

  mat-header-row,
  mat-row {
    min-width: 800px;
  }

  .mat-cell {
    color: $dark-secondary-text;
  }

  .tc-table-column-main {
    flex-grow: 2;
  }

  @media (max-width: 599px) {
    mat-cell:first-child,
    mat-footer-cell:first-child,
    mat-header-cell:first-child {
      padding-left: 16px;
    }

    mat-cell:last-child,
    mat-footer-cell:last-child,
    mat-header-cell:last-child {
      padding-right: 16px;
    }
  }

  .mat-column-logoId {
    flex: 0 1 64px;
    box-sizing: content-box;

    @media (max-width: 599px) {
      flex-basis: 56px;
    }

    &.mat-cell {
      padding-top: 12px;
      padding-bottom: 12px;
    }
  }

  .mat-column-actions {
    flex: 0 0 40px;
    box-sizing: content-box;
    overflow: initial;
  }
}

.tc-table-row-routed {
  cursor: pointer;

  &:focus,
  &:hover {
    background-color: mat.get-color-from-palette($tc-primary-palette, 50);
  }
}

.tc-table-row-selected {
  cursor: default;

  &,
  &:hover {
    background-color: mat.get-color-from-palette($tc-primary-palette, 100);
  }
}

.tc-table-column-main {
  &.mat-cell {
    color: $dark-primary-text;
    font-weight: 500;

    small {
      color: $dark-secondary-text;
      font-weight: normal;
    }
  }
}
