@import '_include';

@import 'material.theme';
@import 'material.overrides';
@import 'material.modifiers';

@import 'components/accordion-form';
@import 'components/card-form';
@import 'components/columns';
@import 'components/container';
@import 'components/cropperjs';
@import 'components/header';
@import 'components/picture-preview';
@import 'components/radio-form';
@import 'components/sortablejs';
@import 'components/table';
@import 'components/text-placeholder';
@import 'util';
@import "~@ng-select/ng-select/themes/material.theme.css";
@import '~ag-grid-community/styles/ag-grid.css';
@import '~ag-grid-community/styles/ag-theme-alpine.css';

html,
body {
  height: 100%;
  margin: 0;
}

body {
  background-color: #fafafa;
  -webkit-font-smoothing: antialiased;
}

a {
  color: currentColor;
}

img {
  max-width: 100%;
}

strong {
  font-weight: 500;
}

h4 {
  font-weight: 500;
}

tc-app {
  display: block;
  min-height: 100%;
}

tc-app,
router-outlet ~ * {
  display: flex;
  flex-direction: column;
}

router-outlet ~ * {
  flex: 1 1 auto;
}

* {
  box-sizing: border-box;
}

h4 {
  font-weight: 400;
}

.ag-table-header {
  padding-left: 10px;
  padding-right: 6px;
}

// ng-select issue https://github.com/ng-select/ng-select/issues/240
.custom-dialog-container .mat-dialog-container,
.custom-dialog-container .mat-dialog-content {
  overflow: visible;
}
